<template>
    <section class="h-auto leaderboard-bg">
        <div class="flex justify-center pt-16 lg:pt-20">
            <div class="flex flex-col justify-center items-center rounded-3xl" id="leaderboard">
                <div class="flex flex-row justify-center items-center">
                <a href="https://roobet.com/?ref=shibbbb" target="_blank"><img v-motion-slide-visible-left src="../assets/roobet-blue-logo.png" class="hidden md:block" alt="roobet-logo"></a>
                <img v-motion-pop-visible src="../assets/sports-bet-title.png" class="px-5" alt="leaderboard">
                <a href="https://roobet.com/?ref=shibbbb" target="_blank"><img v-motion-slide-visible-right src="../assets/logo.png" class="hidden md:block" alt="logo"></a>
                </div>
            </div>
        </div>
        <RoobetSportsLeaderboardSection/>
    </section>
</template>

<script>
import RoobetSportsLeaderboardSection from '@/sections/RoobetSportsLeaderboardSection.vue';

    export default {
        components: {
            RoobetSportsLeaderboardSection
        }
    }
</script>

<style scoped>
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>