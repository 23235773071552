<template>
    <section class="h-auto leaderboard-bg">
    <div class="py-14 lg:py-24 px-8">
      <div class="flex flex-row justify-center items-center">
        <img v-motion-pop-visible src="../assets/prev-lead-title.png" class="" alt="prev-leaderboard">
      </div>
      <div v-if="prevloading == true" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold" style="font-family: Russo One, sans-serif;">Loading Previous Leaderboard...</div>
      <div v-if="prevloading == false"  class="flex justify-center items-center">
        <div class="hidden md:block">
          <label class="toggle-switch mt-8" style="font-family: Zen Dots, sans-serif;">
            <input type="checkbox" @change="changePrevLead()" v-model="selectedPrevLeaderboard"/>
            <div class="slider border border-white" :class="selectedPrevLeaderboard == true ? 'bg-gradient-to-r from-[#007BBE] to-[#A12687]' : 'bg-gradient-to-r from-[#D93105] to-[#8A007C]'"></div>
            <span class="left-label">ROOBET</span>
            <span class="right-label">PACKDRAW</span>
          </label>
        </div>
        <div class="block md:hidden">
          <ul class="flex flex-wrap gap-1 text-sm font-medium text-center text-gray-500 mt-4" style="font-family: Zen Dots, sans-serif;">
              <li @click="selectedPrevLeaderboard = false; changePrevLead();" :class="selectedPrevLeaderboard == false ? 'bg-gradient-to-r from-[#D93105] to-[#8A007C]' : ''" class="rounded-2xl border border-white cursor-pointer">
                  <div class="inline-block px-4 py-3 text-white">Roobet</div>
              </li>
              <li @click="selectedPrevLeaderboard = true; changePrevLead();" :class="selectedPrevLeaderboard == true ? 'bg-gradient-to-r from-[#007BBE] to-[#A12687]' : ''" class="rounded-2xl border border-white cursor-pointer">
                  <div class="inline-block px-4 py-3 text-white">Packdraw</div>
              </li>
          </ul>
        </div>
      </div>
      <div v-if="prevloading == false"  class="hidden md:block">
      <div class="flex justify-center mt-8 mb:mb-24" style="font-family: Russo One, sans-serif;">
        <table class="w-full lg:w-auto border-separate border-spacing-y-6">
          <thead v-motion-slide-visible-bottom class="rounded-2xl" :class="selectedPrevLeaderboard == true ? 'bg-gradient-to-r from-[#007BBE] to-[#A12687]' : 'bg-gradient-to-r from-[#D93105] to-[#8A007C]'">
            <tr> 
              <th
                class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left rounded-l-full">
                Ranks
              </th>
              <th
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                User
              </th>
              <th
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                Amount
              </th>
              <th
                class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right rounded-r-full">
                Prize
              </th>
            </tr>
          </thead>
          <div class="mt-4"></div>
          <tbody>
            <tr v-motion-slide-visible-bottom v-for="(item, index) in displayedPrevLead" :key="index">
              <td
                class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left">
                <div class="flex flex-row items-center gap-2">
                  <div class="text-white">{{ getOrdinalSuffix(index + 1) }}</div>
                  <img src="../assets/logo.png" class="w-[40px]" alt="logo">
                </div>
              </td>
              <td
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                {{ maskString(item.username) }}
              </td>
              <td
                class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                ${{ selectedPrevLeaderboard == false ? item.weightedWagered.toFixed(2) : item.wagerAmount.toFixed(2) }}
              </td>
              <td
                class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-right">
                ${{ selectedPrevLeaderboard == false ? getRoobetRewardValue(index) : getPackDrawRewardValue(index) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
     </div>
      <div v-if="prevloading == false"  class="block md:hidden">
          <div class="flex justify-center mt-8 mb:mb-24 text-xl" style="font-family: Russo One, sans-serif;">
            <table class="w-full lg:w-auto">
              <thead v-motion-slide-visible-bottom>
                <tr>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                    Ranks
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    User
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                    Amount
                  </th>
                  <th
                    class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                    Prize
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-motion-slide-visible-bottom v-for="(item, index) in displayedPrevLead" :key="index">
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left">
                    {{ getOrdinalSuffix(index + 1) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    {{ maskString(item.username) }}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                    ${{ selectedPrevLeaderboard == false ? item.weightedWagered.toFixed(2) : item.wagerAmount.toFixed(2)}}
                  </td>
                  <td
                    class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right">
                    ${{ selectedPrevLeaderboard == false ? getRoobetRewardValue(index) : getPackDrawRewardValue(index) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            displayedPrevLead: [],
            roobetPrevLeaderboard: [],
            packdrawPrevLeaderboard: [],
            prevloading: false,

            selectedPrevLeaderboard: false,
        }
    },
    methods: {
        initPrevLeaderboard(){
            const baseUrl = process.env.VUE_APP_BASE_API;
            this.prevloading = true
            axios.get(`${baseUrl}/api/roobet/leaderboard?previous=true?`, {
            headers: {
                'x-api-key': process.env.VUE_APP_X_API_KEY,
            },
            }).then(rest => {
                this.roobetPrevLeaderboard = rest.data.leaderboard;
                
                axios.get(`${baseUrl}/api/packdraw/leaderboard?previous=true`,{
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                },
                }).then(rest2 => {
                    this.packdrawPrevLeaderboard = rest2.data.leaderboard;
                    this.changePrevLead()
                    this.prevloading = false
                });
            });
        },
        changePrevLead(){
            if(this.selectedPrevLeaderboard == false){
                const prevLeadeboard = this.roobetPrevLeaderboard

                this.displayedPrevLead = prevLeadeboard.slice(0, 10).map((item) => {
                return item;
                });
            }
            else{
                const prevLeadeboard = this.packdrawPrevLeaderboard

                this.displayedPrevLead = prevLeadeboard.slice(0, 9).map((item) => {
                return item;
                });
            }
        },
        maskString(str) {
          if (!str || str.length <= 4) return str;

          const start = str.slice(0, 2);
          const end = str.slice(-2);
          const middleAsterisks = '*'.repeat(str.length - 4);

          return `${start}${middleAsterisks}${end}`;
        },
        getOrdinalSuffix(n) {
            const s = ['th', 'st', 'nd', 'rd'],
                v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
        getRoobetRewardValue(rank) {
          const prizes = JSON.parse(`${process.env.VUE_APP_ROOBET_PREVIOUS_LEADERBOARD_REWARDS}`)
          if (rank >= prizes.length) {
            return prizes[prizes.length-1];
          }
          return prizes[rank];
        },
        getPackDrawRewardValue(rank) {
          const prizes = JSON.parse(`${process.env.VUE_APP_PACKDRAW_PREVIOUS_LEADERBOARD_REWARDS}`)
          if (rank >= prizes.length) {
            return prizes[prizes.length-1];
          }
          return prizes[rank];
        },
    },
    mounted(){
        this.initPrevLeaderboard()
    }
}
</script>

<style scoped>
.leaderboard-bg {
  background-image: url('../assets/leaderboard-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 393px;
  height: 56px;
  background: linear-gradient(to right, #647f8e, #7e5175);
  border-radius: 28px;
  padding: 0 8px;
  cursor: pointer;
}

.toggle-switch input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-switch .slider {
  position: absolute;
  left: 4px;
  width: 192px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 24px;
  transition: transform 0.3s ease;
}

.toggle-switch input:checked + .slider {
  transform: translateX(192px);
}

.toggle-switch span {
  color: white;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
  padding: 0 8px;
}

.toggle-switch .left-label {
  position: absolute;
  left: 35px;
  font-weight: 50;
}

.toggle-switch .right-label {
  position: absolute;
  right: 23px;
  font-weight: 50;
}
</style>