<template>
    <!-- Leaderboard Section -->
    <section class="h-auto">
      <div class="flex justify-center px-8 md:px-8 pt-4 lg:pt-4 lg:pb-14">
        <div class="flex flex-col justify-center items-center rounded-3xl" id="leaderboard">
          <div v-motion-slide-visible-bottom class="text-center text-white font-thin rounded-2xl px-3 py-2 lg:-mt-14 bg-gradient-to-r from-[#D93105] to-[#8A007C]" style="font-family: Russo One, sans-serif;">
            {{ roobetTimer }} {{ loading == true ? "Calculating time": '' }}
          </div>
          <div v-if="loading == true" class="text-center text-white text-2xl md:text-4xl my-10 font-bold" style="font-family: Russo One, sans-serif;">Loading Leaderboard...</div>
          <!-- Temporary text for packdraw updating soon -->
          <!-- <div v-if="selectedLeaderboard" class="text-center text-white text-2xl md:text-4xl mt-10 font-bold" style="font-family: Russo One, sans-serif;">Updating soon...</div>
          <div v-show="selectedLeaderboard == true" v-motion-pop-visible class="flex justify-center text-center text-xl text-white px-2 lg:mt-5 shadow-xl uppercase font-base tracking-wider" style="font-family: Roboto, serif;">all wagers from today untill the leaderboard is updated will be included</div> -->
  
          <div v-if="loading == false"  class="hidden md:block">
            <div class="flex flex-col justify-center md:flex-row gap-4 mt-8 px-8 font-lato">
                <button v-motion-slide-visible-right :disabled="loading == true" @click="activeTab = 'current'; changeLeaderboard();" :class="{'bg-gradient-to-r from-[#D93105] to-[#8A007C]': activeTab === 'current'}" class="text-white font-extrabold bg-transparent hover:bg-gradient-to-r from-[#D93105] to-[#8A007C] border border-white font-base py-4 px-8 relative uppercase rounded-xl">
                    Current Leaderboard
                </button>
                <!-- <button v-motion-slide-visible-left :disabled="loading == true" @click="activeTab = 'previous'; changeLeaderboard();" :class="{'bg-gradient-to-r from-[#D93105] to-[#8A007C]': activeTab === 'previous'}" class="text-white font-extrabold bg-transparent hover:bg-gradient-to-r from-[#D93105] to-[#8A007C] border border-white font-base py-4 px-8 relative uppercase rounded-xl">
                    Previous Leaderboard
                </button> -->
            </div>
            <div class="flex justify-center mt-8 mb-8 mb:mb-24" style="font-family: Russo One, sans-serif;">
              <table class="w-full lg:w-auto border-separate border-spacing-y-6">
                <thead v-motion-slide-visible-bottom class="rounded-2xl bg-gradient-to-r from-[#D93105] to-[#8A007C]">
                  <tr> 
                    <th
                      class="px-1 md:px-[45px] lg:pr-24 lg:pl-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-left rounded-l-full">
                      Ranks
                    </th>
                    <th
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                      User
                    </th>
                    <th
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-center">
                      Amount
                    </th>
                    <th
                      class="px-1 md:px-[45px] lg:pl-24 lg:pr-8 py-2 md:py-4 text-white text-[18px] md:text-xl font-thin text-right rounded-r-full">
                      Prize
                    </th>
                  </tr>
                </thead>
                <div class="mt-4"></div>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in top3" :key="index" class="bg-gradient-to-r from-[#D93105] to-[#8A007C]">
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left rounded-l-full">
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white">{{ getOrdinalSuffix(index + 1) }}</div>
                        <img src="../assets/logo.png" class="w-[40px]" alt="logo">
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center">
                      {{ maskString(item.username) }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-center">
                      ${{ item.wagered.toFixed(2)}}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-4 whitespace-nowrap text-[18px] text-white font-thin text-right rounded-r-full">
                      ${{ activeTab == 'current' ?  getCurrentRewardValue(index) : getPrevRewardValue(index) }}
                    </td>
                  </tr>
                </tbody>
                <div class="mt-8"></div>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in topRest" :key="index" class="bg-[#762402]">
                    <td
                      class="px-1 md:px-[45px] lg:px-10 py-2 md:py-4 whitespace-nowrap text-[18px] font-semibold text-left">
                      <div class="flex flex-row items-center gap-2">
                        <div class="text-white">{{ getOrdinalSuffix(index + 4) }}</div>
                        <img src="../assets/logo.png" class="w-[40px]" alt="logo">
                      </div>
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                      {{ maskString(item.username) }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-24 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-center">
                      ${{ item.wagered.toFixed(2) }}
                    </td>
                    <td
                      class="px-1 md:px-[45px] lg:px-8 py-2 md:py-5 whitespace-nowrap text-[18px] text-white font-thin text-right">
                      ${{ activeTab == 'current' ?  getCurrentRewardValue(index + 3) : getPrevRewardValue(index + 3) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="loading == false"  class="block md:hidden">
            <div class="flex flex-col justify-center md:flex-row gap-4 mt-8 px-8 font-lato">
                <button v-motion-slide-visible-right :disabled="loading == true" @click="activeTab = 'current'; changeLeaderboard();" :class="{'bg-gradient-to-r from-[#D93105] to-[#8A007C]': activeTab === 'current'}" class="text-white font-extrabold bg-transparent hover:bg-gradient-to-r from-[#D93105] to-[#8A007C] border border-white font-base py-4 px-4 relative uppercase rounded-xl">
                    Current Leaderboard
                </button>
                <!-- <button v-motion-slide-visible-left :disabled="loading == true" @click="activeTab = 'previous'; changeLeaderboard();" :class="{'bg-gradient-to-r from-[#D93105] to-[#8A007C]': activeTab === 'previous'}" class="text-white font-extrabold bg-transparent hover:bg-gradient-to-r from-[#D93105] to-[#8A007C] border border-white font-base py-4 px-3 relative uppercase rounded-xl">
                    Previous Leaderboard
                </button> -->
            </div>
            <div class="flex justify-center mt-8 mb-8 mb:mb-24 text-xl" style="font-family: Russo One, sans-serif;">
              <table class="w-full lg:w-auto">
                <thead v-motion-slide-visible-bottom>
                  <tr>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-left">
                      Ranks
                    </th>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                      User
                    </th>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-center">
                      Amount
                    </th>
                    <th
                      class=" md:px-[85px] lg:px-28 py-2 md:py-10 text-white text-[14px] md:text-2xl font-bold text-right">
                      Prize
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-motion-slide-visible-bottom v-for="(item, index) in topForMobile" :key="index">
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-left">
                      {{ getOrdinalSuffix(index + 1) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                      {{ maskString(item.username) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-center">
                      ${{ item.wagered.toFixed(2) }}
                    </td>
                    <td
                      class="px-1 md:px-[85px] lg:px-28 py-2 md:py-5 whitespace-nowrap text-[14px] text-white font-normal text-right">
                      ${{ activeTab == 'current' ?  getCurrentRewardValue(index) : getPrevRewardValue(index) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import moment from 'moment-timezone';
  
  export default {
    components: {
    },
    data() {
      return {
        top3: [],
        topRest: [],
        topForMobile: [],
        roobetTimer: '',
  
        currentLeaderboard: [],
        previousLeaderboard: [],
  
        roobetNotFormattedEndDate: null,
  
        loading: false,
        activeTab: 'current'
      };
    },
    methods: {
      async init() {
        const baseUrl = process.env.VUE_APP_BASE_API;
        this.loading = true
          await axios.get(`${baseUrl}/api/roobet/leaderboard/sportsbetting`,{
            headers: {
              'x-api-key': process.env.VUE_APP_X_API_KEY,
            },
          }).then(res => {
            this.currentLeaderboard = res.data.leaderboard;
            this.roobetNotFormattedEndDate = res.data.endDate
          });

          await axios.get(`${baseUrl}/api/roobet/leaderboard/sportsbetting?previous=true`,{
            headers: {
              'x-api-key': process.env.VUE_APP_X_API_KEY,
            },
          }).then(res => {
            this.previousLeaderboard = res.data.leaderboard;
            this.roobetNotFormattedEndDate = res.data.endDate
          });
  
          if(this.loading == true){
            this.changeLeaderboard()
            this.startTimer();
            this.loading = false
          }
      },
      changeLeaderboard(){
       if(this.activeTab == 'current'){
            const leaderboard = this.currentLeaderboard
              this.top3 = leaderboard.slice(0, 3).map((item) => {
                return item;
              });
    
              this.topRest = leaderboard.slice(3, 5).map((item) => {
                return item;
              });
    
              this.topForMobile = leaderboard.slice(0, 5).map((item) => {
                return item;
              });
        }
        else{
            const leaderboard = this.previousLeaderboard
            this.top3 = leaderboard.slice(0, 3).map((item) => {
                return item;
            });

            this.topRest = leaderboard.slice(3, 5).map((item) => {
                return item;
            });

            this.topForMobile = leaderboard.slice(0, 5).map((item) => {
                return item;
            });
        }
      },
      getOrdinalSuffix(n) {
        const s = ['th', 'st', 'nd', 'rd'],
          v = n % 100;
        return n + (s[(v - 20) % 10] || s[v] || s[0]);
      },
      getCurrentRewardValue(rank) {
        const prizes = JSON.parse(process.env.VUE_APP_SPORT_CURRENT_LEADERBOARD_REWARDS);
        return prizes[rank];
      },
      getPrevRewardValue(rank) {
        const prizes = JSON.parse(process.env.VUE_APP_SPORT_PREVIOUS_LEADERBOARD_REWARDS);
        return prizes[rank];
      },
      maskString(str) {
        if (!str || str.length <= 4) return str;
  
        const start = str.slice(0, 2);
        const end = str.slice(-2);
        const middleAsterisks = '*'.repeat(str.length - 4);
  
        return `${start}${middleAsterisks}${end}`;
      },
      startTimer() {
        const getNextTargetDate = () => {
          const now = moment.utc();
          let targetDate = moment.utc(this.roobetNotFormattedEndDate).subtract({ seconds: 1 })
          
          if (now.isAfter(targetDate)) {
            targetDate.add(2, 'week');
          }
  
          return targetDate;
        };
  
        let targetDate = getNextTargetDate();
        let roobetTimerInterval;
  
        const updateTimer = () => {
          const nowEst = moment.utc();
          const distance = targetDate.diff(nowEst);
  
          if (distance < 0) {
            this.timer = 'Leaderboard ended';
            this.init()
            this.$refs.PrevLeaderBoardSection.init()
            clearInterval(roobetTimerInterval);
  
            targetDate = getNextTargetDate();
            this.startRoobetTimer();
          } else {
            const duration = moment.duration(distance);
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
  
            this.roobetTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
          }
        };
        updateTimer();
        roobetTimerInterval = setInterval(updateTimer, 1000);
    },
    startPackdrawTimer() {
        const getNextTargetDate = () => {
          const now = moment.utc();
          let targetDate = moment.utc(this.packdrawNotFormattedEndDate).subtract({ seconds: 1 })
          
          if (now.isAfter(targetDate)) {
            targetDate.add(1, 'week');
          }
  
          return targetDate;
        };
         
        let targetDate = getNextTargetDate();
        let packdrawTimerInterval;
  
        const updateTimer = () => {
          const nowEst = moment.utc();
          const distance = targetDate.diff(nowEst);
  
          if (distance < 0) {
            this.timer = 'Leaderboard ended';
            this.init()
            this.$refs.PrevLeaderBoardSection.init()
            clearInterval(packdrawTimerInterval);
  
            targetDate = getNextTargetDate();
            this.startPackdrawTimer();
          } else {
            const duration = moment.duration(distance);
            const days = Math.floor(duration.asDays());
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
  
            this.packdrawTimer = `${days}d ${hours}h ${minutes}m ${seconds}s`;
          }
        };
  
        updateTimer();
        packdrawTimerInterval = setInterval(updateTimer, 1000);
      }
    },
    mounted() {
      this.init();
    },
  };
  </script>
  
  <style scoped>
  .toggle-switch {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 393px;
    height: 56px;
    background: linear-gradient(to right, #647f8e, #7e5175);
    border-radius: 28px;
    padding: 0 8px;
    cursor: pointer;
  }
  
  .toggle-switch input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .toggle-switch .slider {
    position: absolute;
    left: 4px;
    width: 192px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 24px;
    transition: transform 0.3s ease;
  }
  
  .toggle-switch input:checked + .slider {
    transform: translateX(192px);
  }
  
  .toggle-switch span {
    color: white;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
    padding: 0 8px;
  }
  
  .toggle-switch .left-label {
    position: absolute;
    left: 35px;
    font-weight: 50;
  }
  
  .toggle-switch .right-label {
    position: absolute;
    right: 23px;
    font-weight: 50;
  }
  </style>
  